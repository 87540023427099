import React, { useEffect, useState } from 'react';
import { fetchQuote } from '../api/api';

const NQPage = () => {
    const [quote, setQuote] = useState(null);

    useEffect(() => {
        const getQuote = async () => {
            try {
                const data = await fetchQuote('NQ');
                setQuote(data);
            } catch (error) {
                console.error('Error fetching NQ quote:', error);
            }
        };

        getQuote();
    }, []);

    return (
        <div>
            <h1>NQ (E-mini NASDAQ-100)</h1>
            {quote ? (
                <div>
                    <p>Current Price: {quote.price}</p>
                    <p>Last Updated: {quote.timestamp}</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default NQPage;