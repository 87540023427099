import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ESPage from './pages/ES';
import NQPage from './pages/NQ';

const App = () => {
    return (
        <Router>
            <nav>
                <ul>
                    <li>
                        <Link to="/es">ES (E-mini S&P 500)</Link>
                    </li>
                    <li>
                        <Link to="/nq">NQ (E-mini NASDAQ-100)</Link>
                    </li>
                </ul>
            </nav>
            <Routes>
                <Route path="/es" element={<ESPage />} />
                <Route path="/nq" element={<NQPage />} />
            </Routes>
        </Router>
    );
};

export default App;
