import axios from 'axios';

const API_BASE_URL = 'https://randextrader.com'; // Replace with your backend URL

// Fetch the latest quotes for a specific symbol
export const fetchQuote = async (symbol) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/quotes`, {
            params: { symbol },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quote:', error);
        throw error;
    }
};