import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const ESPage = () => {
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    const socket = io('https://randextrader.com'); // Replace with your server's URL

    // Listen for real-time quotes
    socket.on('quotes', (data) => {
      console.log('Received quote:', data);
      setQuote(data); // Update state with the received quote
    });

    return () => {
      socket.disconnect(); // Cleanup on component unmount
    };
  }, []);

  return (
    <div>
      <h1>ES (E-mini S&P 500)</h1>
      {quote ? (
        <div>
          <p>Current Price: {quote.price}</p>
          <p>Last Updated: {quote.timestamp}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ESPage;